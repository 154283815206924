<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-calendar</v-icon>
        Calendários Mídias Sociais
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <ListaCalendarioMidiaSociais />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ServicosPrestados",
  data() {
    return {};
  },
  components: {
    ListaCalendarioMidiaSociais: () =>
      import("./components/ListaCalendarioMidiaSociais.vue"),
  },
};
</script>

<style lang="scss"></style>
